body {
    font-family: Arial, sans-serif;
    background-color: white;
  /*  #f0f8ff*/
     color: darkred;
    /*display: flex;*/
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
    overflow: auto;
}

.app {
    background: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 400px;
}

h1 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    color: #008080;
}

.task-list {
    display: flex;
    flex-direction: column;
}

.task-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

    .task-item label {
        margin-left: 10px;
        font-size: 1rem;
    }
